@import "./Root.css";
/* @font-face {
  font-family: ProximaNovaB;
  src: url('./Fonts/ProximaNova-Bold.ttf');
}
@font-face {
  font-family: ProximaNovaM;
  src: url('./Fonts/ProductSans-Medium.ttf');
}
@font-face {
  font-family: ProximaNovaR;
  src: url('./Fonts/ProximaNova-Regular.ttf');
}
@font-face {
  font-family: ProximaNovaS;
  src: url('./Fonts/ProximaNova-Semibold.ttf');
}
@font-face {
  font-family: ProximaNovaL;
  src: url('./Fonts/ProximaNova-Light.ttf');
} */
body {
  margin: 0;
}

html,
* {
  -ms-overflow-style: none; /* IE and Edge */
}
html{
  scrollbar-width: none;
}

.Body_box{
  border-radius: 10px;
  /* border: 1px solid var(--body-box-border); */
  /* background: var(--body-box-background); */
  width: 100%;
  /* margin: 0 2%; */
  padding: 10px 38px;
}

select{
  appearance: none;
}
.blue{
  cursor: pointer;
}

::-webkit-scrollbar {
  width: 0;
}

.ant-popover-arrow::after{
  background-color: var(--background-color) !important;
}
.r-pad{
  padding-right: 38px;
}