.loader-wrapper {
    flex: 1 1;
    display: flex;
    justify-content: center;
    align-items: center;
    height: inherit;
    width: inherit;
  }
  .inside_loader {
    display: flex;
    flex-direction: column;
    align-items: center;
    top: 35%;
    position: fixed;
    padding: 12px;
  }
  .inside_loader p {
    text-align: center;
  }