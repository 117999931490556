@import url("https://fonts.googleapis.com/css2?family=Catamaran:wght@400;600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Mulish:wght@400;600&display=swap");
@import "./Root.css";

body{
  background-color: var(--background-color);
  color: var(--text-color);
  font-family: var(--mulish-font);
}

.App {
  text-align: center;
}
.ant-checkbox-checked .ant-checkbox-inner{
  background-color: #FFC000;
  border-color: #212121;
}
.ant-checkbox .ant-checkbox-inner{
  border: 1px solid #212121 !important;
  padding: 2px;
}
.ant-checkbox .ant-checkbox-inner:after {
  border: 2px solid #212121 !important;
  border-top: 0 !important;
  border-left: 0 !important;
}
/* Customize the check mark */
.ant-checkbox .ant-checkbox-inner:after {
  border: 2px solid #212121 !important;
  border-top: 0 !important;
  border-left: 0 !important;
}

/* Remove hover effect for all checkboxes */
.ant-checkbox-wrapper:hover .ant-checkbox-inner {
  background-color: #ffd451 !important; /* Ensure background does not change */
  border-color: #212121 !important; /* Keep the border the same */
}

select, .select{
  /* background-image: linear-gradient(45deg, transparent 50%, var(--select-arrow) 50%),
    linear-gradient(135deg, var(--select-arrow) 50%, transparent 50%); */
  background-image: url('./Images/Dropdown.svg');
  background-position: calc(100% - 20px) calc(1em + 2px),
    calc(100% - 15px) calc(1em + 2px), 100% 0;
    background-size: 10px 6px;
  background-repeat: no-repeat;
  color: var(--text-color);
  font-family: Mulish;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

[data-theme='dark'] .select {
  background-image: url('./Images/Dropdown_dark.svg');
}
/* option:hover{
  background-color: red !important;
} */

/* antd select */
.ant-select .ant-select-arrow{
  display: none !important;
}
.ant-select-selector{
  width: 125% !important;
  border: none !important;
  background-color: transparent !important;
  box-shadow: none !important;
  outline: none !important;
  color: var(--text-color) !important;
  caret-color: transparent !important;
  cursor: pointer !important;
}
.ant-select-selection-item{
  color: var(--text-color) !important;
  font-family: Mulish;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding-inline-end: 25px !important;
}
.ant-select-single.ant-select-open .ant-select-selection-item .ant-select-selection-placeholder{
  color: var(--text-color) !important;
  font-family: Mulish;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.ant-select-selection-placeholder{
  color: var(--text-color) !important;
  font-family: Mulish;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.ant-select-dropdown .ant-select-item{
  color: var(--text-color) !important;
}
.ant-select-dropdown{
  background-color: var(--background-color) !important;
  color: var(--text-color) !important;
  width: 180px !important;
  font-family: Mulish;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
/* selected items */
.ant-select-dropdown .ant-select-item-option-selected:not(.ant-select-item-option-disabled){
  background-color: transparent !important;
}
/* hover option */
.ant-select-dropdown .ant-select-item-option-active:not(.ant-select-item-option-disabled){
  background-color: #757575 !important;
  color: #ffffff !important;
}
.rc-virtual-list-scrollbar-thumb{
  background-color: #c1c1c1 !important;
  width: 70% !important;
  height: 120px !important;
}

::selection {
  background-color: var(--sidebar);
  color: white;
}
.actions-container{
  gap: 10px;
}

.ant-tooltip .ant-tooltip-inner{
  max-height: 200px !important;
  overflow-y: auto;
  /* background-color: var(--background-color);
  color: var(--text-color); */
  font-family: Mulish;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.ant-tooltip .ant-tooltip-inner::-webkit-scrollbar {
  width: 8px; 
  height: 8px;
}
.ant-tooltip .ant-tooltip-inner::-webkit-scrollbar-track {
  background-color: transparent;
}
.ant-tooltip .ant-tooltip-inner::-webkit-scrollbar-thumb {
  background-color: #c1c1c1;
  border-radius: 4px;
}
